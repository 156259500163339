.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.time-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin: 2px;
  padding: 5px 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.time-number {
  font-size: 0.75rem;
  font-weight: bold;
  color:#007ca2;
}

.time-label {
  font-size: 0.65rem;
  color: #000000;
}

.finish-text {
  font-size: 0.85rem;
  color: #f44336;
  font-weight: bold;
}

